<template>
  <header
    class="te-header co-border-gray-300 border-b py-4 xl:py-0"
  >
    <div class="co-container-lg flex h-full flex-col items-center px-5 text-center xl:flex-row-reverse xl:px-4">
      <div class="basis-full flex-col items-center justify-between text-center xl:flex xl:flex-row-reverse xl:text-right">
        <!-- キャッチコピー -->
        <h2 class="co-display-12 m-0 mb-2 leading-base text-co-gray-700 xl:mb-0 xl:tracking-[.15em]">
          <slot name="catchcopy" />
        </h2>

        <!-- ロゴ -->
        <div class="te-navbar-brand h-full text-center xl:text-left">
          <p
            class="co-display-15 te-copy-word hidden text-black xl:block"
            style="letter-spacing: 0"
          >
            現役エンジニアから学ぶなら
          </p>
          <img
            class="te-logo-image mx-auto h-auto max-w-full"
            src="~/assets/images/Te/TeCommon/Logo/logo-blue.svg"
            alt="テックアカデミー"
          >
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
/* ヘッダー */
.te-header {
  z-index: 1030;
  min-height: 70px;
  @apply w-full bg-co-gray-50;
  @media (min-width: 1200px) {
    height: 70px;
  }
}

/* ロゴ */
.te-navbar-brand {
  @media (min-width: 1200px) {
    width: 12.5rem;
    margin-right: 1rem;
  }
}

/* キャッチコピーテキスト */
.te-copy-word {
  padding-bottom: 0.125rem;
  margin-left: 1.5rem;
  font-size: 0.625rem;
  line-height: 1;
  transform: scale(0.8);
  @media (min-width: 1200px) {
    margin-left: 2.8125rem;
    transform: scale(1);
  }
}

/* ロゴ画像 */
.te-logo-image {
  @media (max-width: 1199.98px) {
    width: 203px;
  }
}
</style>
